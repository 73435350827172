export default function Navbar() {
	return <nav className="nav">
		<a href="/" className="site-title">Mario</a>
		<ul>
			<li>
				<a href="/blogs">Blog</a>
			</li>
			<li>
				<a href="/about">About me</a>
			</li>
		</ul>
	</nav>
}