//import './App.css';
import './styles.css'

import PaperComponent from './components/PaperComponent.js'
import Navbar from './components/Navbar.js'
import About from './pages/About';
import Blog from './pages/Blog';

function App() {
  let component
  switch (window.location.pathname) {
    case "/":
      component = <About /> // TODO: fix
      break
    case "/about":
      component = <About />
      break
    case "/blog":
      component = <Blog />
      break
    default:
      component = <About />
      break
  }
  return (
    <div className="app-container">
      <Navbar />
      <div className="paper-container">
        <div className="paper">
          <PaperComponent />
        </div>
      </div>
    </div> 
  );
}

export default App;
